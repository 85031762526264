<template>
  <div>
    <div class="mapNav">
      <!-- 筛选 -->
      <div class="state">
        <label for="">筛选:</label>
        <!-- <input
          type="text"
          placeholder="请输入车牌号/帐号搜索"
          v-model="keyword"
          @keyup.enter="gogogo"
        /> -->

        <el-select
          filterable
          v-model="keyword"
          placeholder="全部技师"
          @change="gogogo"
        >
          <el-option
            v-for="item1 in techlist"
            :key="item1.uid"
            :value="item1.uid"
            :label="item1.nickname"
          >
            {{ item1.nickname }}
          </el-option>
        </el-select>
      </div>
      <!-- 状态 -->
      <div class="state">
        <el-form ref="form">
          <el-form-item>
            <label class="stateTit" for="">
              状态
              <img
                @mouseenter="zhuang"
                @mouseleave="tai"
                class="icon"
                src="../assets/img/cd/ti.png"
                alt=""
              />
              :
            </label>
            <el-col>
              <el-select v-model="servicestatus" placeholder="请选择状态">
                <el-option
                  v-for="item in option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>

        <!-- 鼠标悬浮问号显示内容 -->
        <div class="latent" v-show="yin">
          <ul>
            <li>
              <img class="latentImg" src="../assets/img/cd/fuwu.png" alt="" />
              <span class="latentTit">离线</span>
            </li>
            <li>
              <img class="latentImg" src="../assets/img/cd/fuwu1.png" alt="" />
              <span class="latentTit">服务中</span>
            </li>
            <li>
              <img class="latentImg" src="../assets/img/cd/kong.png" alt="" />
              <span class="latentTit">空闲</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- 订单类型 -->
      <div class="state">
        <el-form ref="form">
          <el-form-item label="技师归属:">
            <el-col>
              <el-select disabled v-model="guishu" placeholder="技师归属">
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>

      <!-- 车辆类型 -->
      <div class="state">
        <el-form ref="form">
          <el-form-item label="工具类型:">
            <el-col>
              <el-select v-model="type" placeholder="请选择工具类型">
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!-- 按钮 -->

      <div class="btn">
        <button class="ensure" @click="gogogo">确定</button>
        <el-checkbox-button
          v-model="checked2"
          :label="seetit"
          size="large"
          @change="seek"
        />
        <el-checkbox-button
          v-model="checked1"
          :label="cartit"
          size="large"
          @change="jiage"
        />
        <button class="reset" @click="reset">重置</button>
        <button class="reset" @click="gotimeout">刷新</button>
      </div>
    </div>

    <div class="mapDing">
      <div class="map">
        <div id="container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import { reactive, ref } from "vue";
import * as api from "../api/index";
import * as ola from "../api/olawebsock.js";
import Cookies from "js-cookie";
var currentmarker = "";
var infoWindow = "";
export default {
  data() {
    return {
      currentmarker,
      keyword: "",
      yin: false,
      note: false,
      servicestatus: "",
      guishu: "",
      type: "",
      map: "",
      marker: [],
      maker: [],
      techlist: [],
      cartit: "隐藏车牌",
      seetit: "查看所有",
      option: [
        {
          value: "3",
          label: "服务中",
        },
        {
          value: "1",
          label: "离线",
        },
        {
          value: "2",
          label: "空闲",
        },
      ],
      option1: [],
      option2: [
        {
          value: "1",
          label: "App",
        },
        {
          value: "2",
          label: "小程序",
        },
      ],
      emicreg: this.$store.state.emic,
      checked1: true,
      checked2: false,
      detailuid: "",
      detailcontent: "",
      timermap: "",
      busypng: "https://dispatch-test.nituowola.com/img/amap/busy.png",
      freepng: "https://dispatch-test.nituowola.com/img/amap/free.png",
      offlinepng: "https://dispatch-test.nituowola.com/img/amap/offline.png",
      amaplog: 1,
    };
  },
  created() {
    //console.log("router----", this.$route);

    // let who = Cookies.get("ntwl-uid");
    let { uid } = this.$route.query;
    console.log("-------", uid);
    if (uid != undefined && uid != "") {
      this.keyword = uid;
    }
    this.gettechlist();
    window.getdetail = this.getdetail;
    window.call = this.call;
    window.closewindows = this.closewindows;

    if (this.timermap == "") {
      this.timermap = setInterval(() => {
        this.inittimeout();
      }, 10000);
    }

    // if(who == 87)
    // {
    //   this.busypng = 'https://dispatch-test.nituowola.com/img/amap/bak/busy.png';
    //   this.freepng = 'https://dispatch-test.nituowola.com/img/amap/bak/free.png';
    //   this.offlinepng = 'https://dispatch-test.nituowola.com/img/amap/bak/offline.png';
    // }
  },
  beforeUnmount() {},
  mounted() {
    this.init();
    document.addEventListener("click", this.bodyHandler);
  },
  unmounted() {
    this.closed();
    document.removeEventListener("click", this.bodyHandler);
    clearInterval(this.timermap);
    this.timermap = "";
  },
  methods: {
    jiage(e) {
      //console.log("选中了", e);
      this.checked1 = e;
      this.init();
      if (e) {
        this.cartit = "隐藏车牌";
      } else {
        this.cartit = "显示车牌";
      }
    },

    zhuang() {
      this.yin = true;
    },
    tai() {
      this.yin = false;
    },

    bodyHandler() {
      this.note = false;
    },
    async gettechlist() {
      let res = await api.gettechlist(1);
      this.techlist = res.data;
      this.option1 = res.data2;
      this.amaplog = res.logType;
      if (this.amaplog == 2) {
        this.busypng =
          "https://dispatch-test.nituowola.com/img/amap/jt/busy.png";
        this.freepng =
          "https://dispatch-test.nituowola.com/img/amap/jt/free.png";
        this.offlinepng =
          "https://dispatch-test.nituowola.com/img/amap/jt/offline.png";
      }
    },
    reset() {
      this.keyword = "";
      this.servicestatus = "";
      this.guishu = "";
      this.type = "";
    },
    closewindows() {
      ////console.log('关闭窗口');
      currentmarker = "";
      if (infoWindow != "") {
        infoWindow.close();
      }
    },
    // 确定
    gogogo() {
      this.init();
    },
    gotimeout() {
      this.inittimeout();
    },
    // 查看所有
    seek(e) {
      this.checked2 = e;
      if (this.checked2 == true) {
        //console.log("查看所有gs", this.guishu);
        this.guishu = -999;
        this.init();
      } else {
        //console.log("查看所有gs", this.guishu);
        this.guishu = 0;
        this.init();
      }
    },
    async init() {
      let res = await api.getMapNew(
        this.type,
        this.guishu,
        this.keyword,
        this.servicestatus
      );
      this.maker = res.data;

      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [121.483874, 29.861542],
        zoom: 13,
        zooms: [2, 16],
        //viewMode: "3D", //使用3D视图
      });
      this.map = map;
      infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -30),
        isCustom: true,
      });

      if (this.maker.length > 0) {
        let that = this;
        for (let ii = 0; ii < that.maker.length; ii++) {
          let item = that.maker[ii];
          if (this.checked1 == true) {
            var apptype =
              item.is_new == 1
                ? (item.is_np == 0?'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/newmode.png"/>':'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/new_pw.png"/>')
                : "";
            apptype +=
              item.isT == 1
                ? '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/gps_new.png"/>'
                : '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/app_new.png"/>';
            if (item.is_warning == 1) {
              var carnumber =
                '<div class="carnumhaoyellow">' +
                apptype +
                item.car_number +
                "</div>";
            } else {
              var carnumber =
                '<div class="carnumhao">' +
                apptype +
                item.car_number +
                "</div>";
            }

            // var carnumber = '<div style="border:solid 1px #00a0e9; background-color:#ffffff;position: absolute;">'+item.car_number+'</div>';
          } else {
            var carnumber = "";
          }
          if (item.status == 3) {
            var driver = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              map: map,
              content:
                '<div class="makerlabel"><img src="' +
                this.busypng +
                '" style="transform:rotate(' +
                item.angel +
                'deg)"></br>' +
                carnumber +
                "</div>",
              //icon: "'+ this.busypng+'",
              //angle: item.angel,
            });
          } else if (item.status == 2) {
            var driver = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              map: map,
              content:
                '<div class="makerlabel"><img src="' +
                this.freepng +
                '" style="transform:rotate(' +
                item.angel +
                'deg)"></br>' +
                carnumber +
                "</div>",
              //icon: "'+ this.freepng+'",
              //angle: item.angel,
            });
          } else if (item.status == 1) {
            var driver = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              map: map,
              content:
                '<div class="makerlabel"><img src="' +
                this.offlinepng +
                '" style="transform:rotate(' +
                item.angel +
                'deg)"></br>' +
                carnumber +
                "</div>",
              //icon: "'+ this.offlinepng +'",
              //angle: item.angel,
            });
          }

          driver.content = item.uid;
          if (that.emicreg != "") {
            driver.on("click", markerClick);
            this.marker.push(driver);
          } else {
            driver.on("click", markerClick1);
            this.marker.push(driver);
          }

          ////console.log(that.emicreg);
        }
      }
      function markerClick(e) {
        currentmarker = e.target.content;
        $.get(
          "https://dispatch-test.nituowola.com/site/get-map-detail",
          { uid: e.target.content },
          function (data) {
            if (data.code == 1) {
              let item = data.data;

              let that = this;
              if (item.tt == "") {
                var paiban = "";
              } else {
                // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
                if(item.tt == '休息')
                {
                  var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }else{
                  var paiban = `<span class="zhuangtai4">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }

              }
              if (item.tt1 == "") {
                var paiban2 = "";
              } else {
                if(item.tt1 == '休息')
                {
                  var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }else{
                  var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }

              }

              if (that.emicreg != "") {
                var telcall =
                  "<a onclick='call(" +
                  item.tel +
                  ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
              } else {
                var telcall = "<a><div></div></a>";
              }

              if (that.emicreg != "") {
                var callxzs =
                  "<a onclick='call(" +
                  item.xzs +
                  ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
              } else {
                var callxzs = "<a><div></div></a>";
              }

              if(item.is_np == 0)
              {
                var np = '';
                var np1 = '';
              }else{
                var np = `<span class="npgreen">新能源</span>`;
                var np1 =  `<div class="xinxiBox newtimebox">
                      <div class="newtime">
                        <label class="xiTit2" >充电状态:</label>
                        <span class="xinTxt2"
                          >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                        >
                      </div>
                    <div class="newtime" style="margin-left:-21px;">
                        <label class="xiTit" >当前电量:</label>
                        <span class="timeTxt"
                          >${item.np_info.soc}%</span
                        >
                    </div> 
                    <div class="newtime" style="margin-right:7px;">
                      <label class="xiTit2" >续航里程:</label>
                      <span class="xinTxt2"
                        >${item.np_info.range}公里</span
                      >
                    </div>
                  </div>`;
              }

              var tips =
                `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
                `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;

              var content =
                `<div class="mapBox">
                          <div class="mapBoxNav">
                            <a onclick="closewindows()"><img  style="float:right; margin-left:700px; width:20px;height:20px; display:inline;" src='https://dispatch-test.nituowola.com/img/amap/map_close.png' alt='' /></a>
                          </div>
                          <!-- 车辆信息 -->
                          <div class="carXinXi carXinXiDing">
                            <div class="carXinXiNav">
                              <strong>车辆信息</strong>`+np+`
                              <span class="tool">${item.type}</span>
                              <span class="tool">${
                                item.isT == 0 ? "终端未绑定" : "终端已绑定"
                              }</span>
                              <span class="zhuangtai">${item.statustxt}</span>
                              ` +
                paiban +
                paiban2 +
                `
                            </div>


                            <div class="xinxiBox">
                              <label class="xiTit3" for="">&#12288;协作商:</label>
                              <span class="xinTxt3">${item.companyname}/${item.xzs}` +
                callxzs +
                `</span>
                            </div>

                            <div class="xinxiBox">
                                <label class="xiTit3" for="">&#12288;&#12288;技师:</label>
                                <span class="xinTxt3"
                                  >${item.nickname}/
                                  <div class="haocall">
                                  ${item.tel}
                                  ` +
                telcall +
                `
                                </div>
                                  /${item.car_number} ` +
                tips +
                `</span
                                >
                            </div>

                            <div class="xinxiBox">  
                                <label class="xiTit3" for="">当前位置:</label>
                                <span class="xinTxt3"
                                  >${item.address}</span
                                >
                            </div>

                            

                            <div class="xinxiBox newtimebox">
                              <div class="newtime2">
                                <label class="xiTit3" for="">上单完成:</label>
                                <span class="xiTxt3">${item.last_order_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前时间:</label>
                                <span class="xiTxt3">${item.last_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前速度:</label>
                                <span class="xiTxt3">${item.speed}Km/h</span>
                              </div>
                            </div>
                            `+np1+`
                          </div>`;

              if (
                item.next_order_num > 0 ||
                (item.now_order.address != "" &&
                  item.now_order.address != undefined)
              ) {
                content += ` <!-- 当前任务 -->
                                    <div class="taskBox">
                                      <div class="taskBoxNav">
                                        <strong>当前任务</strong>
                                        <span class="zhuangtai">${item.now_order.status}</span>
                                        <span class="tool">${item.now_order.service}</span>
                                      </div>

                                      <div class="xinxiBox">
                                        <div class="xinLeft">
                                          <label class="xiTit3" for="">合作单位:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.companyname}</span
                                          >
                                        </div>
                                        <div class="xinRight">
                                          <label class="xiTit3" for="">&#12288;&#12288;救援时间:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.rescueTime}</span
                                          >
                                        </div>
                                      </div>

                                      <div class="xinxiBox">
                                          <label class="xiTit3" for="">&#12288;任务点:</label>
                                          <span class="taskTit3"
                                          >${item.now_order.address}</span
                                        >
                                      </div>`;
                if (item.now_order.destination != "") {
                  content += `<div class="xinxiBox">
                                                          <label class="xiTit3" for="">&#12288;目的地:</label>
                                                          <span class="taskTit3"
                                                            >${item.now_order.destination}</span
                                                          >
                                                        </div>`;
                }
                content += `</div>`;

                if (item.next_order_num > 0) {
                  content += `<div class="pressSheet">
                                    <div class="pressSheetNav">
                                      <strong>压单信息</strong>
                                      <span class="pressSheetNum">${item.next_order_num}</span>
                                    </div>

                                    <table class="maptable" border="0" cellspacing="0">
                                      <thead>
                                        <th class="partner">合作单位</th>
                                        <th class="serviceType">救援时间</th>
                                        <th class="taskPoint">任务点</th>
                                        <th class="destination">目的地</th>
                                      </thead>
                                      <tbody>`;
                  for (var i = 0; i < item.next_order_num; i++) {
                    content += `<tr
                                          :class=" 
                                            (index % 2 == 0 && !changecolor) ||
                                            (index % 2 !== 0 && changecolor)
                                              ? 'bg_color'
                                              : 'bg_color2'
                                          "
                                        >
                                          <td class="td-partner" style="text-align: center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                                          <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                                          <td class="td-taskPoint">
                                            ${item.next_order[i].address}
                                          </td>
                                          <td class="td-destination">
                                            ${item.next_order[i].destination}
                                          </td>
                                        </tr>`;
                  }

                  content += `</tbody>
                                    </table>
                                  </div>;
                                </div>`;
                }
              }
            }

            infoWindow.setContent(content);
            infoWindow.open(map, e.target.getPosition());
          },
          "json"
        );
      }

      function markerClick1(e) {
        //let that = this;
        currentmarker = e.target.content;
        $.get(
          "https://dispatch-test.nituowola.com/site/get-map-detail",
          { uid: e.target.content },
          function (data) {
            if (data.code == 1) {
              let item = data.data;

              let that = this;
              if (item.tt == "") {
                var paiban = "";
              } else {
                // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
                if(item.tt == '休息')
                {
                  var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }else{
                  var paiban = `<span class="zhuangtai4">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }

              }
              if (item.tt1 == "") {
                var paiban2 = "";
              } else {
                if(item.tt1 == '休息')
                {
                  var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }else{
                  var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }

              }

              if(item.is_np == 0)
              {
                var np = '';
                var np1 = '';
              }else{
                var np = `<span class="npgreen">新能源</span>`;
                var np1 =  `<div class="xinxiBox newtimebox">
                      <div class="newtime">
                        <label class="xiTit2" >充电状态:</label>
                        <span class="xinTxt2"
                          >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                        >
                      </div>
                      <div class="newtime" style="margin-left:-21px;">
                        <label class="xiTit" >当前电量:</label>
                        <span class="timeTxt"
                          >${item.np_info.soc}%</span
                        >
                    </div> 
                    <div class="newtime" style="margin-right:7px;">
                      <label class="xiTit2" >续航里程:</label>
                      <span class="xinTxt2"
                        >${item.np_info.range}公里</span
                      >
                    </div>
                  </div>`;
              }

              var telcall = "<a><div></div></a>";

              var callxzs = "<a><div></div></a>";

              var tips =
                `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
                `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;

              var content =
                `<div class="mapBox">
                          <div class="mapBoxNav">
                            <a onclick="closewindows()"><img  style="float:right; margin-left:700px; width:20px;height:20px; display:inline;" src='https://dispatch-test.nituowola.com/img/amap/map_close.png' alt='' /></a>
                          </div>
                          <!-- 车辆信息 -->
                          <div class="carXinXi carXinXiDing">
                            <div class="carXinXiNav">
                              <strong>车辆信息</strong>
                              `+np+`
                              <span class="tool">${item.type}</span>
                              <span class="tool">${
                                item.isT == 0 ? "终端未绑定" : "终端已绑定"
                              }</span>
                              <span class="zhuangtai">${item.statustxt}</span>
                              ` +
                paiban +
                paiban2 +
                `
                            </div>


                            <div class="xinxiBox">
                              <label class="xiTit3" for="">&#12288;协作商:</label>
                              <span class="xinTxt3">${item.companyname}/${item.xzs}` +
                callxzs +
                `</span>
                            </div>

                            <div class="xinxiBox">
                                <label class="xiTit3" for="">&#12288;&#12288;技师:</label>
                                <span class="xinTxt3"
                                  >${item.nickname}/
                                  <div class="haocall">
                                  ${item.tel}
                                  ` +
                telcall +
                `
                                </div>
                                  /${item.car_number} ` +
                tips +
                `</span
                                >
                            </div>

                            <div class="xinxiBox">  
                                <label class="xiTit3" for="">当前位置:</label>
                                <span class="xinTxt3"
                                  >${item.address}</span
                                >
                            </div>

                            

                            <div class="xinxiBox newtimebox">
                              <div class="newtime2">
                                <label class="xiTit3" for="">上单完成:</label>
                                <span class="xiTxt3">${item.last_order_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前时间:</label>
                                <span class="xiTxt3">${item.last_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前速度:</label>
                                <span class="xiTxt3">${item.speed}Km/h</span>
                              </div>
                            </div>
                            `+np1+`
                          </div>`;

              if (
                item.next_order_num > 0 ||
                (item.now_order.address != "" &&
                  item.now_order.address != undefined)
              ) {
                content += ` <!-- 当前任务 -->
                                    <div class="taskBox">
                                      <div class="taskBoxNav">
                                        <strong>当前任务</strong>
                                        <span class="zhuangtai">${item.now_order.status}</span>
                                        <span class="tool">${item.now_order.service}</span>
                                      </div>

                                      <div class="xinxiBox">
                                        <div class="xinLeft">
                                          <label class="xiTit3" for="">合作单位:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.companyname}</span
                                          >
                                        </div>
                                        <div class="xinRight">
                                          <label class="xiTit3" for="">&#12288;&#12288;救援时间:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.rescueTime}</span
                                          >
                                        </div>
                                      </div>

                                      <div class="xinxiBox">
                                          <label class="xiTit3" for="">&#12288;任务点:</label>
                                          <span class="taskTit3"
                                          >${item.now_order.address}</span
                                        >
                                      </div>`;
                if (item.now_order.destination != "") {
                  content += `<div class="xinxiBox">
                                                          <label class="xiTit3" for="">&#12288;目的地:</label>
                                                          <span class="taskTit3"
                                                            >${item.now_order.destination}</span
                                                          >
                                                        </div>`;
                }
                content += `</div>`;

                if (item.next_order_num > 0) {
                  content += `<div class="pressSheet">
                                    <div class="pressSheetNav">
                                      <strong>压单信息</strong>
                                      <span class="pressSheetNum">${item.next_order_num}</span>
                                    </div>

                                    <table class="maptable" border="0" cellspacing="0">
                                      <thead>
                                        <th class="partner">合作单位</th>
                                        <th class="serviceType">救援时间</th>
                                        <th class="taskPoint">任务点</th>
                                        <th class="destination">目的地</th>
                                      </thead>
                                      <tbody>`;
                  for (var i = 0; i < item.next_order_num; i++) {
                    content += `<tr
                                          :class=" 
                                            (index % 2 == 0 && !changecolor) ||
                                            (index % 2 !== 0 && changecolor)
                                              ? 'bg_color'
                                              : 'bg_color2'
                                          "
                                        >
                                        <td class="td-partner" style="text-align: center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                                          <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                                          <td class="td-taskPoint">
                                            ${item.next_order[i].address}
                                          </td>
                                          <td class="td-destination">
                                            ${item.next_order[i].destination}
                                          </td>
                                        </tr>`;
                  }

                  content += `</tbody>
                                    </table>
                                  </div>;
                                </div>`;
                }
              }
            }

            infoWindow.setContent(content);
            infoWindow.open(map, e.target.getPosition());
          },
          "json"
        );
      }

      map.setFitView();
    },
    async inittimeout() {
      let res = await api.getMapNew(
        this.type,
        this.guishu,
        this.keyword,
        this.servicestatus
      );
      this.maker = res.data;

      if (currentmarker != "") {
        infoWindow.close();
      }

      if (this.map != "") {
        var map = this.map;
        let center = map.getCenter();
        let zoom = map.getZoom();
        let xx = this.marker;
        map.remove(xx);
        this.marker = [];

        infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          isCustom: true,
        });

        if (this.maker.length > 0) {
          let that = this;
          for (let ii = 0; ii < that.maker.length; ii++) {
            let item = that.maker[ii];
            if (this.checked1 == true) {
              var apptype =
              item.is_new == 1
              ? (item.is_np == 0?'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/newmode.png"/>':'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/new_pw.png"/>')
              : "";
              apptype +=
                item.isT == 1
                  ? '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/gps_new.png"/>'
                  : '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/app_new.png"/>';
              if (item.is_warning == 1) {
                var carnumber =
                  '<div class="carnumhaoyellow">' +
                  apptype +
                  item.car_number +
                  "</div>";
              } else {
                var carnumber =
                  '<div class="carnumhao">' +
                  apptype +
                  item.car_number +
                  "</div>";
              }
            } else {
              var carnumber = "";
            }
            if (item.status == 3) {
              var driver = new AMap.Marker({
                position: new AMap.LngLat(item.lng, item.lat),
                map: map,
                content:
                  '<div class="makerlabel"><img src="' +
                  this.busypng +
                  '" style="transform:rotate(' +
                  item.angel +
                  'deg)"></br>' +
                  carnumber +
                  "</div>",
              });
            } else if (item.status == 2) {
              var driver = new AMap.Marker({
                position: new AMap.LngLat(item.lng, item.lat),
                map: map,
                content:
                  '<div class="makerlabel"><img src="' +
                  this.freepng +
                  '" style="transform:rotate(' +
                  item.angel +
                  'deg)"></br>' +
                  carnumber +
                  "</div>",
              });
            } else if (item.status == 1) {
              var driver = new AMap.Marker({
                position: new AMap.LngLat(item.lng, item.lat),
                map: map,
                content:
                  '<div class="makerlabel"><img src="' +
                  this.offlinepng +
                  '" style="transform:rotate(' +
                  item.angel +
                  'deg)"></br>' +
                  carnumber +
                  "</div>",
              });
            }

            driver.content = item.uid;
            if (that.emicreg != "") {
              driver.on("click", markerClick);
              this.marker.push(driver);
            } else {
              driver.on("click", markerClick1);
              this.marker.push(driver);
            }

            if (item.uid == currentmarker) {
              driver.emit("click", { target: driver });
            }
          }
        }
        function markerClick(e) {
          currentmarker = e.target.content;
          $.get(
            "https://dispatch-test.nituowola.com/site/get-map-detail",
            { uid: e.target.content },
            function (data) {
              if (data.code == 1) {
                let item = data.data;

                let that = this;
                if (item.tt == "") {
                var paiban = "";
              } else {
                // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
                if(item.tt == '休息')
                {
                  var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }else{
                  var paiban = `<span class="zhuangtai4">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }

              }
              if (item.tt1 == "") {
                var paiban2 = "";
              } else {
                if(item.tt1 == '休息')
                {
                  var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }else{
                  var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }

              }

                if (that.emicreg != "") {
                  var telcall =
                    "<a onclick='call(" +
                    item.tel +
                    ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
                } else {
                  var telcall = "<a><div></div></a>";
                }

                if (that.emicreg != "") {
                  var callxzs =
                    "<a onclick='call(" +
                    item.xzs +
                    ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
                } else {
                  var callxzs = "<a><div></div></a>";
                }

                if(item.is_np == 0)
              {
                var np = '';
                var np1 = '';
              }else{
                var np = `<span class="npgreen">新能源</span>`;
                var np1 =  `<div class="xinxiBox newtimebox">
                      <div class="newtime">
                        <label class="xiTit2" >充电状态:</label>
                        <span class="xinTxt2"
                          >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                        >
                      </div>
                    <div class="newtime" style="margin-left:-21px;">
                        <label class="xiTit" >当前电量:</label>
                        <span class="timeTxt"
                          >${item.np_info.soc}%</span
                        >
                    </div> 
                    <div class="newtime" style="margin-right:7px;">
                      <label class="xiTit2" >续航里程:</label>
                      <span class="xinTxt2"
                        >${item.np_info.range}公里</span
                      >
                    </div>
                  </div>`;
              }

                var tips =
                  `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
                  `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;

                var content =
                  `<div class="mapBox">
                          <div class="mapBoxNav">
                            <a onclick="closewindows()"><img  style="float:right; margin-left:700px; width:20px;height:20px; display:inline;" src='https://dispatch-test.nituowola.com/img/amap/map_close.png' alt='' /></a>
                          </div>
                          <!-- 车辆信息 -->
                          <div class="carXinXi carXinXiDing">
                            <div class="carXinXiNav">
                              <strong>车辆信息</strong>
                              `+ np +`
                              <span class="tool">${item.type}</span>
                              <span class="tool">${
                                item.isT == 0 ? "终端未绑定" : "终端已绑定"
                              }</span>
                              <span class="zhuangtai">${item.statustxt}</span>
                              ` +
                  paiban +
                  paiban2 +
                  `
                            </div>


                            <div class="xinxiBox">
                              <label class="xiTit3" for="">&#12288;协作商:</label>
                              <span class="xinTxt3">${item.companyname}/${item.xzs}` +
                  callxzs +
                  `</span>
                            </div>

                            <div class="xinxiBox">
                                <label class="xiTit3" for="">&#12288;&#12288;技师:</label>
                                <span class="xinTxt3"
                                  >${item.nickname}/
                                  <div class="haocall">
                                  ${item.tel}
                                  ` +
                  telcall +
                  `
                                </div>
                                  /${item.car_number} ` +
                  tips +
                  `</span
                                >
                            </div>

                            <div class="xinxiBox">  
                                <label class="xiTit3" for="">当前位置:</label>
                                <span class="xinTxt3"
                                  >${item.address}</span
                                >
                            </div>

                            

                            <div class="xinxiBox newtimebox">
                              <div class="newtime2">
                                <label class="xiTit3" for="">上单完成:</label>
                                <span class="xiTxt3">${item.last_order_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前时间:</label>
                                <span class="xiTxt3">${item.last_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前速度:</label>
                                <span class="xiTxt3">${item.speed}Km/h</span>
                              </div>
                            </div>
                            `+ np1 +`
                          </div>`;

                if (
                  item.next_order_num > 0 ||
                  (item.now_order.address != "" &&
                    item.now_order.address != undefined)
                ) {
                  content += ` <!-- 当前任务 -->
                                    <div class="taskBox">
                                      <div class="taskBoxNav">
                                        <strong>当前任务</strong>
                                        <span class="zhuangtai">${item.now_order.status}</span>
                                        <span class="tool">${item.now_order.service}</span>
                                      </div>

                                      <div class="xinxiBox">
                                        <div class="xinLeft">
                                          <label class="xiTit3" for="">合作单位:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.companyname}</span
                                          >
                                        </div>
                                        <div class="xinRight">
                                          <label class="xiTit3" for="">&#12288;&#12288;救援时间:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.rescueTime}</span
                                          >
                                        </div>
                                      </div>

                                      <div class="xinxiBox">
                                          <label class="xiTit3" for="">&#12288;任务点:</label>
                                          <span class="taskTit3"
                                          >${item.now_order.address}</span
                                        >
                                      </div>`;
                  if (item.now_order.destination != "") {
                    content += `<div class="xinxiBox">
                                                          <label class="xiTit3" for="">&#12288;目的地:</label>
                                                          <span class="taskTit3"
                                                            >${item.now_order.destination}</span
                                                          >
                                                        </div>`;
                  }
                  content += `</div>`;

                  if (item.next_order_num > 0) {
                    content += `<div class="pressSheet">
                                    <div class="pressSheetNav">
                                      <strong>压单信息</strong>
                                      <span class="pressSheetNum">${item.next_order_num}</span>
                                    </div>

                                    <table class="maptable" border="0" cellspacing="0">
                                      <thead>
                                        <th class="partner">合作单位</th>
                                        <th class="serviceType">救援时间</th>
                                        <th class="taskPoint">任务点</th>
                                        <th class="destination">目的地</th>
                                      </thead>
                                      <tbody>`;
                    for (var i = 0; i < item.next_order_num; i++) {
                      content += `<tr
                                          :class=" 
                                            (index % 2 == 0 && !changecolor) ||
                                            (index % 2 !== 0 && changecolor)
                                              ? 'bg_color'
                                              : 'bg_color2'
                                          "
                                        >
                                        <td class="td-partner" style="text-align: center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                                          <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                                          <td class="td-taskPoint">
                                            ${item.next_order[i].address}
                                          </td>
                                          <td class="td-destination">
                                            ${item.next_order[i].destination}
                                          </td>
                                        </tr>`;
                    }

                    content += `</tbody>
                                    </table>
                                  </div>;
                                </div>`;
                  }
                }
              }

              infoWindow.setContent(content);
              infoWindow.open(map, e.target.getPosition());
            },
            "json"
          );
        }

        function markerClick1(e) {
          currentmarker = e.target.content;
          $.get(
            "https://dispatch-test.nituowola.com/site/get-map-detail",
            { uid: e.target.content },
            function (data) {
              if (data.code == 1) {
                let item = data.data;

                let that = this;
                if (item.tt == "") {
                var paiban = "";
              } else {
                // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
                if(item.tt == '休息')
                {
                  var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }else{
                  var paiban = `<span class="zhuangtai4">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }

              }
              if (item.tt1 == "") {
                var paiban2 = "";
              } else {
                if(item.tt1 == '休息')
                {
                  var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }else{
                  var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }

              }

              if(item.is_np == 0)
              {
                var np = '';
                var np1 = '';
              }else{
                var np = `<span class="npgreen">新能源</span>`;
                var np1 =  `<div class="xinxiBox newtimebox">
                      <div class="newtime">
                        <label class="xiTit2" >充电状态:</label>
                        <span class="xinTxt2"
                          >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                        >
                      </div>
                    <div class="newtime" style="margin-left:-21px;">
                        <label class="xiTit" >当前电量:</label>
                        <span class="timeTxt"
                          >${item.np_info.soc}%</span
                        >
                    </div> 
                    <div class="newtime" style="margin-right:7px;">
                      <label class="xiTit2" >续航里程:</label>
                      <span class="xinTxt2"
                        >${item.np_info.range}公里</span
                      >
                    </div>
                  </div>`;
              }


                var telcall = "<a><div></div></a>";

                var callxzs = "<a><div></div></a>";

                var tips =
                  `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
                  `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;

                var content =
                  `<div class="mapBox">
                          <div class="mapBoxNav">
                            <a onclick="closewindows()"><img  style="float:right; margin-left:700px; width:20px;height:20px; display:inline;" src='https://dispatch-test.nituowola.com/img/amap/map_close.png' alt='' /></a>
                          </div>
                          <!-- 车辆信息 -->
                          <div class="carXinXi carXinXiDing">
                            <div class="carXinXiNav">
                              <strong>车辆信息</strong>
                              `+np+`
                              <span class="tool">${item.type}</span>
                              <span class="tool">${
                                item.isT == 0 ? "终端未绑定" : "终端已绑定"
                              }</span>
                              <span class="zhuangtai">${item.statustxt}</span>
                              ` +
                  paiban +
                  paiban2 +
                  `
                            </div>


                            <div class="xinxiBox">
                              <label class="xiTit3" for="">&#12288;协作商:</label>
                              <span class="xinTxt3">${item.companyname}/${item.xzs}` +
                  callxzs +
                  `</span>
                            </div>

                            <div class="xinxiBox">
                                <label class="xiTit3" for="">&#12288;&#12288;技师:</label>
                                <span class="xinTxt3"
                                  >${item.nickname}/
                                  <div class="haocall">
                                  ${item.tel}
                                  ` +
                  telcall +
                  `
                                </div>
                                  /${item.car_number} ` +
                  tips +
                  `</span
                                >
                            </div>

                            <div class="xinxiBox">  
                                <label class="xiTit3" for="">当前位置:</label>
                                <span class="xinTxt3"
                                  >${item.address}</span
                                >
                            </div>

                            

                            <div class="xinxiBox newtimebox">
                              <div class="newtime2">
                                <label class="xiTit3" for="">上单完成:</label>
                                <span class="xiTxt3">${item.last_order_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前时间:</label>
                                <span class="xiTxt3">${item.last_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前速度:</label>
                                <span class="xiTxt3">${item.speed}Km/h</span>
                              </div>
                            </div>
                            `+np1+`
                          </div>`;

                if (
                  item.next_order_num > 0 ||
                  (item.now_order.address != "" &&
                    item.now_order.address != undefined)
                ) {
                  content += ` <!-- 当前任务 -->
                                    <div class="taskBox">
                                      <div class="taskBoxNav">
                                        <strong>当前任务</strong>
                                        <span class="zhuangtai">${item.now_order.status}</span>
                                        <span class="tool">${item.now_order.service}</span>
                                      </div>

                                      <div class="xinxiBox">
                                        <div class="xinLeft">
                                          <label class="xiTit3" for="">合作单位:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.companyname}</span
                                          >
                                        </div>
                                        <div class="xinRight">
                                          <label class="xiTit3" for="">&#12288;&#12288;救援时间:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.rescueTime}</span
                                          >
                                        </div>
                                      </div>

                                      <div class="xinxiBox">
                                          <label class="xiTit3" for="">&#12288;任务点:</label>
                                          <span class="taskTit3"
                                          >${item.now_order.address}</span
                                        >
                                      </div>`;
                  if (item.now_order.destination != "") {
                    content += `<div class="xinxiBox">
                                                          <label class="xiTit3" for="">&#12288;目的地:</label>
                                                          <span class="taskTit3"
                                                            >${item.now_order.destination}</span
                                                          >
                                                        </div>`;
                  }
                  content += `</div>`;

                  if (item.next_order_num > 0) {
                    content += `<div class="pressSheet">
                                    <div class="pressSheetNav">
                                      <strong>压单信息</strong>
                                      <span class="pressSheetNum">${item.next_order_num}</span>
                                    </div>

                                    <table class="maptable" border="0" cellspacing="0">
                                      <thead>
                                        <th class="partner">合作单位</th>
                                        <th class="serviceType">救援时间</th>
                                        <th class="taskPoint">任务点</th>
                                        <th class="destination">目的地</th>
                                      </thead>
                                      <tbody>`;
                    for (var i = 0; i < item.next_order_num; i++) {
                      content += `<tr
                                          :class=" 
                                            (index % 2 == 0 && !changecolor) ||
                                            (index % 2 !== 0 && changecolor)
                                              ? 'bg_color'
                                              : 'bg_color2'
                                          "
                                        >
                                        <td class="td-partner" style="text-align: center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                                          <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                                          <td class="td-taskPoint">
                                            ${item.next_order[i].address}
                                          </td>
                                          <td class="td-destination">
                                            ${item.next_order[i].destination}
                                          </td>
                                        </tr>`;
                    }

                    content += `</tbody>
                                    </table>
                                  </div>;
                                </div>`;
                  }
                }
              }

              infoWindow.setContent(content);
              infoWindow.open(map, e.target.getPosition());
            },
            "json"
          );
        }

        // function mapZoomstart() {
        //   let that = this;
        //   if (that.timermap != "") {
        //     //////console.log('开始缩放')
        //     clearInterval(that.timermap);
        //     that.timermap = "";
        //   }
        // }
        // function mapZoom() {
        //   let that = this;
        //   if (that.timermap == "") {
        //     // ////console.log('开始刷新')
        //     // ////console.log(that.timermap);
        //     that.timermap = setInterval(() => {
        //       that.inittimeout();
        //     }, 10000);
        //   }
        // }
        // function mapZoomend() {
        //   let that = this;
        //   //console.log('缩放结束')
        //   if (that.timermap != "") {
        //     ////console.log('缩放结束111')
        //     clearInterval(that.timermap);
        //     that.timermap = "";
        //     ////console.log(that.timermap)
        //   }
        // }
        map.setZoomAndCenter(zoom, center);

        // map.on("zoomstart", mapZoomstart);
        // map.on("zoomchange", mapZoom);
        // map.on("zoomend", mapZoomend);
        //map.setFitView();
      } else {
        var map = new AMap.Map("container", {
          resizeEnable: true,
          center: [121.483874, 29.861542],
          zoom: 13,
          zooms: [2, 16],
          //viewMode: "3D", //使用3D视图
        });
        this.map = map;
        infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          isCustom: true,
        });

        if (this.maker.length > 0) {
          let that = this;
          for (let ii = 0; ii < that.maker.length; ii++) {
            let item = that.maker[ii];
            if (this.checked1 == true) {
              var apptype =
                item.is_new == 1
                ? (item.is_np == 0?'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/newmode.png"/>':'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/new_pw.png"/>')
                : "";
              apptype +=
                item.isT == 1
                  ? '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/gps_new.png"/>'
                  : '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/app_new.png"/>';
              if (item.is_warning == 1) {
                var carnumber =
                  '<div class="carnumhaoyellow">' +
                  apptype +
                  item.car_number +
                  "</div>";
              } else {
                var carnumber =
                  '<div class="carnumhao">' +
                  apptype +
                  item.car_number +
                  "</div>";
              }

              // var carnumber = '<div style="border:solid 1px #00a0e9; background-color:#ffffff;position: absolute;">'+item.car_number+'</div>';
            } else {
              var carnumber = "";
            }
            if (item.status == 3) {
              var driver = new AMap.Marker({
                position: new AMap.LngLat(item.lng, item.lat),
                map: map,
                content:
                  '<div class="makerlabel"><img src="' +
                  this.busypng +
                  '" style="transform:rotate(' +
                  item.angel +
                  'deg)"></br>' +
                  carnumber +
                  "</div>",
                //icon: "'+ this.busypng+'",
                //angle: item.angel,
              });
            } else if (item.status == 2) {
              var driver = new AMap.Marker({
                position: new AMap.LngLat(item.lng, item.lat),
                map: map,
                content:
                  '<div class="makerlabel"><img src="' +
                  this.freepng +
                  '" style="transform:rotate(' +
                  item.angel +
                  'deg)"></br>' +
                  carnumber +
                  "</div>",
                //icon: "'+ this.freepng+'",
                //angle: item.angel,
              });
            } else if (item.status == 1) {
              var driver = new AMap.Marker({
                position: new AMap.LngLat(item.lng, item.lat),
                map: map,
                content:
                  '<div class="makerlabel"><img src="' +
                  this.offlinepng +
                  '" style="transform:rotate(' +
                  item.angel +
                  'deg)"></br>' +
                  carnumber +
                  "</div>",
                //icon: "'+ this.offlinepng +'",
                //angle: item.angel,
              });
            }

            driver.content = item.uid;
            if (that.emicreg != "") {
              driver.on("click", markerClick);
              this.marker.push(driver);
            } else {
              driver.on("click", markerClick1);
              this.marker.push(driver);
            }

            ////console.log(that.emicreg);
          }
        }
        function markerClick(e) {
          //let that = this;
          $.get(
            "https://dispatch-test.nituowola.com/site/get-map-detail",
            { uid: e.target.content },
            function (data) {
              if (data.code == 1) {
                let item = data.data;

                let that = this;
                if (item.tt == "") {
                var paiban = "";
              } else {
                // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
                if(item.tt == '休息')
                {
                  var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }else{
                  var paiban = `<span class="zhuangtai4">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }

              }
              if (item.tt1 == "") {
                var paiban2 = "";
              } else {
                if(item.tt1 == '休息')
                {
                  var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }else{
                  var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }

              }


                if (that.emicreg != "") {
                  var telcall =
                    "<a onclick='call(" +
                    item.tel +
                    ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
                } else {
                  var telcall = "<a><div></div></a>";
                }

                if (that.emicreg != "") {
                  var callxzs =
                    "<a onclick='call(" +
                    item.xzs +
                    ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
                } else {
                  var callxzs = "<a><div></div></a>";
                }

                if(item.is_np == 0)
              {
                var np = '';
                var np1 = '';
              }else{
                var np = `<span class="npgreen">新能源</span>`;
                var np1 =  `<div class="xinxiBox newtimebox">
                      <div class="newtime">
                        <label class="xiTit2" >充电状态:</label>
                        <span class="xinTxt2"
                          >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                        >
                      </div>
                    <div class="newtime" style="margin-left:-21px;">
                        <label class="xiTit" >当前电量:</label>
                        <span class="timeTxt"
                          >${item.np_info.soc}%</span
                        >
                    </div> 
                    <div class="newtime" style="margin-right:7px;">
                      <label class="xiTit2" >续航里程:</label>
                      <span class="xinTxt2"
                        >${item.np_info.range}公里</span
                      >
                    </div>
                  </div>`;
              }

                var tips =
                  `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
                  `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;

                var content =
                  `<div class="mapBox">
                          <div class="mapBoxNav">
                            <a onclick="closewindows()"><img  style="float:right; margin-left:700px; width:20px;height:20px; display:inline;" src='https://dispatch-test.nituowola.com/img/amap/map_close.png' alt='' /></a>
                          </div>
                          <!-- 车辆信息 -->
                          <div class="carXinXi carXinXiDing">
                            <div class="carXinXiNav">
                              <strong>车辆信息</strong>
                              `+np+`
                              <span class="tool">${item.type}</span>
                              <span class="tool">${
                                item.isT == 0 ? "终端未绑定" : "终端已绑定"
                              }</span>
                              <span class="zhuangtai">${item.statustxt}</span>
                              ` +
                  paiban +
                  paiban2 +
                  `
                            </div>


                            <div class="xinxiBox">
                              <label class="xiTit3" for="">&#12288;协作商:</label>
                              <span class="xinTxt3">${item.companyname}/${item.xzs}` +
                  callxzs +
                  `</span>
                            </div>

                            <div class="xinxiBox">
                                <label class="xiTit3" for="">&#12288;&#12288;技师:</label>
                                <span class="xinTxt3"
                                  >${item.nickname}/
                                  <div class="haocall">
                                  ${item.tel}
                                  ` +
                  telcall +
                  `
                                </div>
                                  /${item.car_number} ` +
                  tips +
                  `</span
                                >
                            </div>

                            <div class="xinxiBox">  
                                <label class="xiTit3" for="">当前位置:</label>
                                <span class="xinTxt3"
                                  >${item.address}</span
                                >
                            </div>

                            

                            <div class="xinxiBox newtimebox">
                              <div class="newtime2">
                                <label class="xiTit3" for="">上单完成:</label>
                                <span class="xiTxt3">${item.last_order_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前时间:</label>
                                <span class="xiTxt3">${item.last_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前速度:</label>
                                <span class="xiTxt3">${item.speed}Km/h</span>
                              </div>
                            </div>
                            `+np1+`
                          </div>`;

                if (
                  item.next_order_num > 0 ||
                  (item.now_order.address != "" &&
                    item.now_order.address != undefined)
                ) {
                  content += ` <!-- 当前任务 -->
                                    <div class="taskBox">
                                      <div class="taskBoxNav">
                                        <strong>当前任务</strong>
                                        <span class="zhuangtai">${item.now_order.status}</span>
                                        <span class="tool">${item.now_order.service}</span>
                                      </div>

                                      <div class="xinxiBox">
                                        <div class="xinLeft">
                                          <label class="xiTit3" for="">合作单位:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.companyname}</span
                                          >
                                        </div>
                                        <div class="xinRight">
                                          <label class="xiTit3" for="">&#12288;&#12288;救援时间:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.rescueTime}</span
                                          >
                                        </div>
                                      </div>

                                      <div class="xinxiBox">
                                          <label class="xiTit3" for="">&#12288;任务点:</label>
                                          <span class="taskTit3"
                                          >${item.now_order.address}</span
                                        >
                                      </div>`;
                  if (item.now_order.destination != "") {
                    content += `<div class="xinxiBox">
                                                          <label class="xiTit3" for="">&#12288;目的地:</label>
                                                          <span class="taskTit3"
                                                            >${item.now_order.destination}</span
                                                          >
                                                        </div>`;
                  }
                  content += `</div>`;

                  if (item.next_order_num > 0) {
                    content += `<div class="pressSheet">
                                    <div class="pressSheetNav">
                                      <strong>压单信息</strong>
                                      <span class="pressSheetNum">${item.next_order_num}</span>
                                    </div>

                                    <table class="maptable" border="0" cellspacing="0">
                                      <thead>
                                        <th class="partner">合作单位</th>
                                        <th class="serviceType">救援时间</th>
                                        <th class="taskPoint">任务点</th>
                                        <th class="destination">目的地</th>
                                      </thead>
                                      <tbody>`;
                    for (var i = 0; i < item.next_order_num; i++) {
                      content += `<tr
                                          :class=" 
                                            (index % 2 == 0 && !changecolor) ||
                                            (index % 2 !== 0 && changecolor)
                                              ? 'bg_color'
                                              : 'bg_color2'
                                          "
                                        >
                                        <td class="td-partner" style="text-align: center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                                          <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                                          <td class="td-taskPoint">
                                            ${item.next_order[i].address}
                                          </td>
                                          <td class="td-destination">
                                            ${item.next_order[i].destination}
                                          </td>
                                        </tr>`;
                    }

                    content += `</tbody>
                                    </table>
                                  </div>;
                                </div>`;
                  }
                }
              }

              infoWindow.setContent(content);
              infoWindow.open(map, e.target.getPosition());
            },
            "json"
          );
        }

        function markerClick1(e) {
          //let that = this;
          $.get(
            "https://dispatch-test.nituowola.com/site/get-map-detail",
            { uid: e.target.content },
            function (data) {
              if (data.code == 1) {
                let item = data.data;

                let that = this;
                if (item.tt == "") {
                var paiban = "";
              } else {
                // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
                if(item.tt == '休息')
                {
                  var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }else{
                  var paiban = `<span class="zhuangtai4">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
                }

              }
              if (item.tt1 == "") {
                var paiban2 = "";
              } else {
                if(item.tt1 == '休息')
                {
                  var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }else{
                  var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao">明</i>
                              ${item.tt1}
                          </span>`;
                }

              }

              if(item.is_np == 0)
              {
                var np = '';
                var np1 = '';
              }else{
                var np = `<span class="npgreen">新能源</span>`;
                var np1 =  `<div class="xinxiBox newtimebox">
                      <div class="newtime">
                        <label class="xiTit2" >充电状态:</label>
                        <span class="xinTxt2"
                          >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                        >
                      </div>
                    <div class="newtime" style="margin-left:-21px;">
                        <label class="xiTit" >当前电量:</label>
                        <span class="timeTxt"
                          >${item.np_info.soc}%</span
                        >
                    </div> 
                    <div class="newtime" style="margin-right:7px;">
                      <label class="xiTit2" >续航里程:</label>
                      <span class="xinTxt2"
                        >${item.np_info.range}公里</span
                      >
                    </div>
                  </div>`;
              }


                var telcall = "<a><div></div></a>";

                var callxzs = "<a><div></div></a>";

                var tips =
                  `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
                  `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;

                var content =
                  `<div class="mapBox">
                          <div class="mapBoxNav">
                            <a onclick="closewindows()"><img  style="float:right; margin-left:700px; width:20px;height:20px; display:inline;" src='https://dispatch-test.nituowola.com/img/amap/map_close.png' alt='' /></a>
                          </div>
                          <!-- 车辆信息 -->
                          <div class="carXinXi carXinXiDing">
                            <div class="carXinXiNav">
                              <strong>车辆信息</strong>
                              `+np+`
                              <span class="tool">${item.type}</span>
                              <span class="tool">${
                                item.isT == 0 ? "终端未绑定" : "终端已绑定"
                              }</span>
                              <span class="zhuangtai">${item.statustxt}</span>
                              ` +
                  paiban +
                  paiban2 +
                  `
                            </div>


                            <div class="xinxiBox">
                              <label class="xiTit3" for="">&#12288;协作商:</label>
                              <span class="xinTxt3">${item.companyname}/${item.xzs}` +
                  callxzs +
                  `</span>
                            </div>

                            <div class="xinxiBox">
                                <label class="xiTit3" for="">&#12288;&#12288;技师:</label>
                                <span class="xinTxt3"
                                  >${item.nickname}/
                                  <div class="haocall">
                                  ${item.tel}
                                  ` +
                  telcall +
                  `
                                </div>
                                  /${item.car_number}  ` +
                  tips +
                  `</span
                                >
                            </div>

                            <div class="xinxiBox">  
                                <label class="xiTit3" for="">当前位置:</label>
                                <span class="xinTxt3"
                                  >${item.address}</span
                                >
                            </div>

                            

                            <div class="xinxiBox newtimebox">
                              <div class="newtime2">
                                <label class="xiTit3" for="">上单完成:</label>
                                <span class="xiTxt3">${item.last_order_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前时间:</label>
                                <span class="xiTxt3">${item.last_time}</span>
                              </div>
                              <div class="newtime2">
                                <label class="xiTit3" for="">当前速度:</label>
                                <span class="xiTxt3">${item.speed}Km/h</span>
                              </div>
                            </div>
                            `+np1+`
                          </div>`;

                if (
                  item.next_order_num > 0 ||
                  (item.now_order.address != "" &&
                    item.now_order.address != undefined)
                ) {
                  content += ` <!-- 当前任务 -->
                                    <div class="taskBox">
                                      <div class="taskBoxNav">
                                        <strong>当前任务</strong>
                                        <span class="zhuangtai">${item.now_order.status}</span>
                                        <span class="tool">${item.now_order.service}</span>
                                      </div>

                                      <div class="xinxiBox">
                                        <div class="xinLeft">
                                          <label class="xiTit3" for="">合作单位:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.companyname}</span
                                          >
                                        </div>
                                        <div class="xinRight">
                                          <label class="xiTit3" for="">&#12288;&#12288;救援时间:</label>
                                          <span class="xiTxt3"
                                            >${item.now_order.rescueTime}</span
                                          >
                                        </div>
                                      </div>

                                      <div class="xinxiBox">
                                          <label class="xiTit3" for="">&#12288;任务点:</label>
                                          <span class="taskTit3"
                                          >${item.now_order.address}</span
                                        >
                                      </div>`;
                  if (item.now_order.destination != "") {
                    content += `<div class="xinxiBox">
                                                          <label class="xiTit3" for="">&#12288;目的地:</label>
                                                          <span class="taskTit3"
                                                            >${item.now_order.destination}</span
                                                          >
                                                        </div>`;
                  }
                  content += `</div>`;

                  if (item.next_order_num > 0) {
                    content += `<div class="pressSheet">
                                    <div class="pressSheetNav">
                                      <strong>压单信息</strong>
                                      <span class="pressSheetNum">${item.next_order_num}</span>
                                    </div>

                                    <table class="maptable" border="0" cellspacing="0">
                                      <thead>
                                        <th class="partner">合作单位</th>
                                        <th class="serviceType">救援时间</th>
                                        <th class="taskPoint">任务点</th>
                                        <th class="destination">目的地</th>
                                      </thead>
                                      <tbody>`;
                    for (var i = 0; i < item.next_order_num; i++) {
                      content += `<tr
                                          :class=" 
                                            (index % 2 == 0 && !changecolor) ||
                                            (index % 2 !== 0 && changecolor)
                                              ? 'bg_color'
                                              : 'bg_color2'
                                          "
                                        >
                                        <td class="td-partner" style="text-align: center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                                          <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                                          <td class="td-taskPoint">
                                            ${item.next_order[i].address}
                                          </td>
                                          <td class="td-destination">
                                            ${item.next_order[i].destination}
                                          </td>
                                        </tr>`;
                    }

                    content += `</tbody>
                                    </table>
                                  </div>;
                                </div>`;
                  }
                }
              }

              infoWindow.setContent(content);
              infoWindow.open(map, e.target.getPosition());
            },
            "json"
          );
        }
  
        map.setFitView();
      }
    },
    closed() {
      let mapbox = $(".mapBox");
      mapbox.css("display", "none");
      this.closewindows();
    },
    call(tel) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
      });
    },
  },
  watch: {
    keyword(newval) {
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.mapNav {
  // width: 1730px;
  height: 80px;
  background-color: #fff;
  // position: absolute;
  // right: 0;
  z-index: 1;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.map {
  float: left;
  margin-top: 5px;
}
.shai {
  float: left;
  margin-top: 24px;
  margin-left: 48px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
input {
  width: 240px;
  height: 32px;
  padding-left: 12px;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-left: 8px;
}
input:hover {
  border: 1px solid #c0c4cc;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
i {
  display: inline-block;
  font-style: normal;
  margin-left: 8px;
  margin-top: 12px;
}
.state {
  float: left;
  margin-top: 24px;
  margin-left: 15px;
  position: relative;
}
.el-select {
  width: 200px;
  margin-left: 8px;
}
.el-col-24 {
  flex: 0;
}
.icon {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  cursor: pointer;
}
.btn {
  float: right;
  margin-left: 24px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
}
.reset,
.ensure {
  width: 60px;
  height: 32px;
  cursor: pointer;
  border-radius: 0;
}
.reset {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-left: 10px;
}
.reset:hover {
  color: #40bcff;
  border: 1px solid #40bcff;
}
.btn /deep/ .el-checkbox.is-bordered.el-checkbox--large {
  // width: 60px;
  padding: 0 8x;
  height: 32px;
  box-sizing: border-box;
  border-radius: 0;
  margin-left: 10px;
}
.btn /deep/ .el-checkbox-button__inner {
  margin-left: 10px;
  border: 1px solid #dcdfe6;
  border-radius: 0;
}
.btn /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #2c68ff;
  box-shadow: none !important;
}
.btn /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner:hover {
  background-color: #40a9ff;
  box-shadow: none !important;
}

.ensure {
  margin-left: 12px;
  background: #2c68ff;
  border: 0;
  color: #fff;
}
.ensure:hover {
  background: #40a9ff;
}
ul {
  list-style: none;
}
// 鼠标悬浮
.latent {
  width: 100px;
  height: 114px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 0px;
  border-radius: 8px;
  z-index: 5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.latent::before {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  position: absolute;
  right: 55px;
  top: -14px;
  border: 7px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.latentImg {
  width: 22px;
  height: 22px;
  vertical-align: middle;
}
.latentTit {
  font-size: 14px;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.85);
  vertical-align: top;
}
.latent ul li {
  margin-top: 12px;
  margin-left: 12px;
}

.mapBox {
  width: 600px;
  // width: 345px;
  height: auto;
  max-height: 700px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  padding-bottom: 12px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
}
.mapBox::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.mapBoxNav {
  // position: fixed;
  width: 600px;
  height: 48px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  padding-right: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #d9d9d9;
  z-index: 5;
  background-color: #fff;
}
.amap-info-close {
  z-index: 5;
}
.carXinXi,
.taskBox,
.pressSheet {
  margin: 24px;
  width: 550px;
}
.carXinXiDing {
  margin-top: 72px;
}
.carXinXiNav,
.taskBoxNav,
.pressSheetNav {
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  border-left: 2px solid #2c68ff;
  // background-color: pink;
  // width: 550px;
}
strong {
  font-size: 16px;
  font-weight: 500;
}
.tool {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  box-sizing: border-box;
  color: #000;
  font-size: 12px;
}

.npgreen{
  background-color: #27CC8B;
  border: 1px solid #27CC8B;
  color:#fff;
  box-sizing: border-box;
  font-size: 12px;
  padding: 1px 3px;
}


.zhuangtai {
  background-color: #d9e4ff;
  padding: 1px 8px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
}

.zhuangtai1 {
  background-color: #d9e4ff;
  padding: 1px 8px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
}
.tool,
.zhuangtai {
  margin-left: 10px;
}
.pressSheetNum {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  box-sizing: border-box;
  font-size: 12px;
  margin-left: 10px;
}
.xinxiBox {
  // width: 550px;
  display: flex;
  // align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
}

.newtimebox {
  justify-content: space-between;
}

.callImg {
  margin-left: 10px;
}
.maptable {
  margin-top: 10px;
}
thead {
  background-color: #f5f5f5;
  height: 32px !important;
}
.taskPoint,
.destination {
  width: 200px !important;
}
.partner {
  width: 150px !important;
}
.serviceType {
  width: 150px !important;
}

tbody tr {
  height: 70px !important;
}
.partner,
.serviceType,
.taskPoint,
.destination {
  text-align: center;
}
.td-serviceType {
  text-align: center;
}
td {
  padding: 10px;
  text-align: left;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}
.bg_color2 {
  background-color: #fafafa !important;
}

.mationNav {
  height: 46px;
  border-bottom: 1px solid #e0e0e0;
}
.xinTit {
  width: 64px;
  height: 22px;
  font-size: 16px;
  float: left;
  margin-top: 12px;
  margin-left: 12px;
  overflow: hidden;
  // -webkit-line-clamp: 1;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
}
.an {
  float: right;
  margin-top: 12px;
  margin-right: 30px;
  padding: 0 5px;
}
.xu,
.xian {
  display: inline-block;
  height: 22px;
  font-size: 12px;
  border-radius: 2px;
  padding: 0 5px;
}
.xian {
  background: #f6ffed;
  color: #52c41a;
  margin-left: 4px;
  border: 1px solid #b7eb8f;
}
.xu {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
i {
  font-style: normal;
}
.Collaboration {
  margin-left: 24px;
  margin-top: 12px;
}
.CollaborationTit,
.locationTit,
.placeTit {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  vertical-align: top;
}
.CollaborationTit {
  width: 230px;
  display: inline-block;
}
.placeTit {
  display: inline-block;
  width: 230px;
}
.xie,
.zhi,
.wei {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  vertical-align: top;
}
.location {
  margin-top: 10px;
  margin-left: 10px;
}
.place {
  margin-top: 10px;
  margin-left: 38px;
}

.tel {
  display: inline-block;
  margin-top: 12px;
}
.amap-info-content {
  padding: 0;
  border-radius: 8px;
}
.amap-info-close {
  position: absolute;
  right: 12px !important;
  top: 16px;
}
#container {
  width: 1728px;
  height: 755px;
}
</style>

<style>
table {
}
.taskPoint,
.destination {
  width: 225px !important;
}
.partner {
  width: 100px !important;
}
.serviceType {
  width: 150px !important;
}
.amp-gai1 {
  width: 40px;
  height: 18px;
  background: red;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 18px;
  color: white;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
}
.amap-marker-label {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.carnumhao {
  display: flex;
  align-items: center;
  width: 110px;
  max-width: 110px;
  padding: 3px 5px;
  background-color: #2c68ff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -20px;
  text-align: center;
}

.carnumhaoyellow {
  display: flex;
  align-items: center;
  width: 110px;
  max-width: 110px;
  padding: 3px 5px;
  border: solid 2px;
  border-color: red;
  background-color: #2c68ff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -20px;
  text-align: center;
}
.mapimg {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.xinLeft,
.xinRight {
  width: 290px;
  display: flex;
  /* align-items: center; */
}
.xiTit3 {
  /* width: 150px; */
  color: rgba(0, 0, 0, 0.45);
  margin-right: 10px;
}
.xinTxt3 {
  width: 450px;
  display: flex;
}
.xiTxt3 {
  width: 170px;
  word-break: break-all;
}
.taskTit3 {
  width: 450px;
}
.haocall {
  display: flex;
}
.jiao2 {
  font-style: normal;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border: 1px solid #2c68ff;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 8px 0;
}
.jiao {
  font-style: normal;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border: 1px solid #2c68ff;
  background-color: #2c68ff;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 8px 0;
}
.zhuangtai4 {
  background-color: #d9e4ff;
  padding: 1px 16px;
  box-sizing: border-box;
  margin-left: 10px;
  color: #2c68ff;
  font-size: 12px;
  position: relative;
}
.zhuangtai5 {
  background-color: #bcbcbc;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
  padding: 1px 16px;
  box-sizing: border-box;
  position: relative;
}
</style>
